@use 'fonts' as *;

ion-icon {
  @include font-size-24;
  --color: var(--ion-text-color);

  display: inline-block;
  position: relative;
  pointer-events: auto;

  &.icon-extraSmall {
    @include font-size-10;
  }

  &.icon-small {
    @include font-size-18;
  }

  &.icon-large {
    @include font-size-32;
  }
}
